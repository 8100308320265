<template>
	<div>
		<v-toolbar style="outline:gray" flat>
				<v-spacer />
				<v-flex md1>
					<v-layout justify-start>
						<v-btn
							:disabled="parseInt(page)==1"
							@click="changePrevData"
							class="ma-2"
							text
							icon
						>
							<v-icon :disabled="parseInt(page)==1">mdi-chevron-left</v-icon>
						</v-btn>
					</v-layout>
				</v-flex>
				<v-flex md2 style="padding-top:15px;text-align: center">
					<p class="font-weight-light text--primary">
						Page
						{{ page }}
						out of
						{{ getTotalPage() }}
					</p>
				</v-flex>
				<v-flex md1 align-self-end>
					<v-layout justify-end>
						<v-btn
							@click="changeNextData"
							class="ma-2"
							text
							icon
						>
							<v-icon >mdi-chevron-right</v-icon>
						</v-btn>
					</v-layout>
				</v-flex>
			</v-toolbar>
    <v-simple-table dense>
      <template v-slot:default>
        <thead>
          <tr>
            <th>#</th>
            <th>Analysis</th>
            <th>Department</th>
            <th>Branch</th>
            <th>Section</th>
            <th width="25%">Chart of Accounts</th>
            <th>Debit</th>
            <th>Credit</th>
            <th>Remarks</th>
            <th>Delete</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(detail, detail_key) in filterTableData(doc_detail.details, page)"
            :key="detail.detail_key"
          >
            <td>{{ detail_key + 1 + ((rowPage * parseInt(page)) - rowPage) }}</td>
            <td>
              <v-autocomplete
                class="remove-underline"
                v-model="detail.payee_id"
                :items="payees"
                v-on:focus="getPayees"
                hide-details
				dense
                item-text="payee_desc"
                item-value="id"
                @change="setDetails(detail.payee_id, detail_key + ((rowPage * parseInt(page)) - rowPage), doc_detail.document_number)"
              ></v-autocomplete>
            </td>
            <td>
              <v-autocomplete
                class="remove-underline"
                v-model="detail.department_id"
                :items="dataDepts"
                editable
				dense
                hide-details
                item-text="dept_desc"
                item-value="id"
                v-on:focus="getDeptData($event, detail_key + ((rowPage * parseInt(page)) - rowPage), doc_detail.document_number)"
                @change="getDeptData($event, detail_key + ((rowPage * parseInt(page)) - rowPage), doc_detail.document_number)"
              ></v-autocomplete>
            </td>
            <td>
              <v-autocomplete
                class="remove-underline"
                v-model="detail.branch_id"
                :items="detail.branches"
				dense
                v-on:focus="getBranchData(detail.department_id, detail_key + ((rowPage * parseInt(page)) - rowPage), doc_detail.document_number)"
                editable
                hide-details
                item-text="code_name"
                item-value="id"
              ></v-autocomplete>
            </td>
            <td>
              <v-autocomplete
                class="remove-underline"
                v-model="detail.section_id"
                :items="detail.sections"
				dense
                v-on:focus="getSectionData(detail.department_id, detail_key + ((rowPage * parseInt(page)) - rowPage), doc_detail.document_number)"
                editable
                hide-details
                item-text="section_name"
                item-value="section_id"
              ></v-autocomplete>
            </td>
            <td>
              <v-autocomplete
                class="remove-underline"
                v-model="detail.account_code"
                :items="coaItem"
				dense
                hide-details
                item-text="code_name"
                item-value="acct_code"
              ></v-autocomplete>
            </td>
            <td>
              <v-text-field
                v-model="detail.debit_amount"
                type="text"
                class="input_number text-right"
                hide-details
				dense
                :readonly="parseFloat(detail.credit_amount.replace(/,/g, '')) > 0"
              ></v-text-field>
            </td>
            <td>
              <v-text-field
                v-model="detail.credit_amount"
                type="text"
				dense
                class="input_number text-right"
                hide-details
                :readonly="parseFloat(detail.debit_amount.replace(/,/g, '')) > 0"
              ></v-text-field>
            </td>
            <td>
              <v-text-field
                v-model="detail.remarks"
                 type="text"
                editable
				dense
                hide-details
              ></v-text-field>
            </td>
            <td>
              <v-btn icon @click="removeDetail(doc_detail.document_number, detail_key + ((rowPage * parseInt(page)) - rowPage))">
                <v-icon small color="red">delete</v-icon>
              </v-btn>
            </td>
          </tr>
          <tr>
            <td colspan="4">
              <b>Total</b>
            </td>
            <td>&nbsp;</td>
            <td class="text-right">
            </td>
            <td class="text-right">
              <b>{{doc_detail.overallDebit}}</b>
            </td>
            <td class="text-right">
              <b>{{doc_detail.overallCredit}}</b>
            </td>
            <td>&nbsp;</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-card-actions>
      <v-btn right dark color="primary" @click="addDetail(doc_detail.document_number)">
        <v-icon>mdi-plus</v-icon>
        <span>Add new Row</span>
      </v-btn>
      <!-- <input id="xlsx" :data-doc-number="doc_detail.document_number" type="file" @change="importExcel"> -->
      <upload-btn 
        @file-update="importExcel"
        :document_id="parseInt(doc_detail.document_number)"
        title="Import Spreadsheet">
      </upload-btn>
    </v-card-actions>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import numeral from 'numeral';
// import xlsx from 'xlsx';
import jsonToFormData from 'json-form-data';
//startOfMonth, endOfMonth, addYears, addDays, addMonths, parseISO 
import { format } from 'date-fns';
import UploadButton from '@/components/partials/AppImportButton';

export default {
	props: {
		batchDetails: Array,
		documentDetails: Array,
		dataDepts: Array,
		dataSections: Array,
		dataBanks: Array,
		doc_detail: Object,
		currency_id: Number
	},
	components: {
		'upload-btn': UploadButton,
	},
	data() {
		return {
			detailHeaders:[
				{ text: 'Analysis', value: 'payee_id' },
				{ text: 'Department', value: 'department_id' },
				{ text: 'Branch', value: 'branch_id' },
				{ text: 'Section', value: 'section_id' },
				{ text: 'Chart of Accounts', value: 'account_code' },
				{ text: 'Debit', value: 'debit_amount' },
				{ text: 'Credit', value: 'credit_amount' },
			],
			batch_datepicker: false,
			cheque_datepicker: [],
			due_datepicker: [],
			voucher_datepicker: [],
			details: this.documentDetails,
			date_format: '####-##-##',
			overallDebit: 0,
			overallCredit: 0,
			populate_entries: [],
			regular_trx: [],
			generated_cheques: [],
			snackbar: false,
			isvalid: false,
			updateCashReceipt: false,
			cancelCashReceipt: false,
			image_title: [],
			upload_file: [],
			delete_file: [],
			disbursement_type: [],
			regular_trx_type: [],
			txn_type: [],
			upload_files: '',
			file: '',
			minDate: '',
			maxDate: '',
			error_message: 'Error: Please fill out the required fields',
			batch_number_rules: [
				v => !!v || 'Batch Number is required',
			],
			date_rules: [
				v => !!v || 'Date is required',
			],
			payee_rules: [
				v => !!v || 'Payor is required',
			],
			bank_rules: [
				v => !!v || 'Bank is required',
			],
			attHeaders: [
				{
					text: 'File Name',
					align: 'left',
					sortable: false,
					value: 'filename'
				},
				{ 
					text: 'Actions',
					align: 'right',
					sortable: false,
					value: 'file',
				},
			],
			page: 1,
			pages: [],
			totalPage: 1,
			rowPage: process.env.VUE_APP_TRX_DETAIL_ROW,
			documentNumber : '',
		};
	},
	computed: {
		...mapGetters({
			// coas: 'coa/coaSubs',
			coaData: 'coa/coaData',
			// depts: 'dept/depts',
			branches: 'branch/branches',
			// sections: 'section/sections',
			payeefile: 'payefile/payefiles',
			banks: 'bank/banks',
			bpbanks: 'bpbank/bpbanks',
			payeebanks: 'payeebank/payeebanks',
			// alphataxcodes: 'alphataxcode/alphataxcodes',
			// transactions: 'transaction/transactions',
			headers: 'trxdisb/headers',
			regulartrx: 'regularTxns/regulartxns',
			editDialog: 'trxdisb/editDialog',
			currUser: 'auth/currUser',
			trxdisbDetails: 'trxdisb/trxdisbDetails',
			trxdisbDetailed: 'trxdisb/trxdisbDetailed',
			coaItem: 'coa/coaCurr',
			uploadEntries: 'trxdisb/uploadEntries',
		}),
		payees(){
			let data = this.payeefile.filter(e => e.status === 1);
			return data;
		}
	},
	watch: {
		currency_id: {
			handler(val){
				console.log(val);
				this.$emit('update:currency_id', val);
				this.getCoaCurr(val);
			},
			deep: true
		},
		batchDetails: {
			handler(val){
				this.$emit('update:batchDetails', val);
			}, 
			deep: true
		},
		documentDetails: {
			handler(val){
				this.$emit('update:documentDetails', val);
			}, 
			deep: true
		},
		uploadEntries: {
			handler(val){
				this.setNewEntries(val);
			}
		}
	},
	mounted() {
		this.getCoaCurr(this.currency_id);
		this.getBranches();
	},
	methods: {
		// getCoas(){
		// 	this.$store.dispatch('coa/getCoaDropdown');
		// },
		// getSections() {
		// 	this.$store.dispatch('section/getSectionDropdown');
		// },
		getBranches() {
			this.$store.dispatch('branch/getBranchDropdown');
		},
		// getDepts() {
		// 	this.$store.dispatch('dept/getDeptDropdown');
		// },
		getPayees() {
			this.$store.dispatch('payefile/getPayefileDropdown'); 
		},
		getPayeeBanks() {
			this.$store.dispatch('payeebank/getPayeeBankDropdown');
		},
		getBpBanks() {
			this.$store.dispatch('bpbank/getBpbankDropdown');
		},
		getCoaCurr(curr_id){
			let data = [];
			this.coaData.map(coa => {
				if(coa.currency_id == curr_id){
					data.push(coa);
				}
			});
			
			this.$store.commit('coa/SET_COA_CURR', data, { root: true });
		},
		addDetail(key) {
			let acct_code = '';
			this.dataBanks.filter(bankDetails => {
				if(bankDetails.id == this.batchDetails.bank_account_id){
					acct_code = parseInt(bankDetails.acct_code);
				}
			});

			this.documentDetails.filter((header) => {
				let newRow = {};

				if (header.document_number === key) {
					newRow = {
						payee_id: 0,
						department_id: 0,
						section_id: 0,
						regionalhead_id: 1,
						branch_id: 0,
						document_number: key,
						document_date: format(new Date(), 'yyyy-MM-dd'),
						reference_number: key,
						account_code: acct_code,
						remarks: 'New Entry',
						debit_amount: numeral(0).format('0,0.[00]'),
						credit_amount: numeral(0).format('0,0.[00]')
					};
				}

				header.details.filter(detail => {
					if(detail.document_number == key){
						newRow.batch_number = detail.batch_number;
						newRow.reference_number = key;
						newRow.document_number = key,
						newRow.document_date = format(new Date(), 'yyyy-MM-dd'),
						newRow.payee_id = detail.payee_id;
						newRow.department_id = detail.department_id;
						newRow.section_id = detail.section_id;
						newRow.regionalhead_id = detail.regionalhead_id;
						newRow.branch_id = detail.branch_id;
						newRow.debit_amount = numeral(0).format('0,0.[00]');
						newRow.credit_amount = numeral(0).format('0,0.[00]');
					}
				});

				header.details.push(newRow);
				this.getDeptData(newRow.department_id, this.documentDetails[0].details.length - 1, key);
			});
		},
		removeDetail(document_number, header_key) {
			this.documentDetails.filter((header) => {
				let countDetails = [];
				countDetails = header.details.filter(detail => detail.document_number === document_number);

				if (countDetails.length > 1) {
					header.details.filter((detail, index) => {
						if (detail.document_number === document_number) {
							if (index === header_key) {
								header.details.splice(header_key, 1);
							}
						}
					});
				}				
			});
		},
		filterTableData(data, page){
			let newData = data;
			var perChunk = this.rowPage; // items per chunk   
			var result = newData.reduce((resultArray, item, index) => { 
				const chunkIndex = Math.floor(index/perChunk);

				if(!resultArray[chunkIndex]) {
					resultArray[chunkIndex] = []; // start a new chunk
				}

				resultArray[chunkIndex].push(item);
				return resultArray;
			}, []);
			return result[page-1];
		},
		async importExcel(e, doc_id) {
			this.documentNumber = doc_id;
			const files = e.target.files, f = files[0];
			let payload = {
				upload_file : f,
				edit_by: this.currUser.id
			};
			var options = {
				initialFormData: new FormData(),
				showLeafArrayIndexes: true,
				includeNullValues: false,
				mapping: function(value) {
					if (typeof value === 'boolean') {
						return +value ? '1': '0';
					}
					return value;
				}
			};

			var convertedFormData = jsonToFormData(payload, options);
			await this.$store.dispatch('trxdisb/uploadEntries', convertedFormData);
		},
		setNewEntries(detail){
			let sections = this.dataSections;
			let branches = this.branches;

			const docNumber = this.documentNumber;
			this.documentDetails.filter((header) => {
				if (header.document_number == docNumber) {
					header.enable_tax_base = '0';
					header.check_type = '';

					//Overwrites the current table
					const specificDetail = header.details.filter(e => e.document_number != docNumber);

					header.details = [];
					header.details = specificDetail;

					//Re-initialize push
					header.details.push({
						section_id: 1,
						regionalhead_id: 1,
						document_number: docNumber,
						document_date: '2019-11-04',
						remarks: 'New Entry',
						account_code: '',
					});

					header.details.map(function(detailData) {
						if (detailData.document_number == docNumber) {
							detail.forEach((excelValue, index) => {
								if (index === 0) {
									detailData.batch_number = header.batch_number;
									detailData.payee_id = excelValue.payee_id;
									detailData.department_id = excelValue.department_id;
									detailData.section_id = (!excelValue.section_id ? 0 : excelValue.section_id);
									detailData.regionalhead_id = (!excelValue.section_id ? 0 : excelValue.section_id);
									detailData.branch_id = excelValue.branch_id;
									detailData.account_code = excelValue.account_code;
									detailData.currency_id = excelValue.currency_id;
									detailData.document_number = docNumber;
									detailData.document_date = format(new Date(), 'yyyy-MM-dd');
									detailData.remarks = excelValue.remarks;
									detailData.debit_amount = excelValue.debit_amount;
									detailData.credit_amount = excelValue.credit_amount;
									detailData.sections = sections;
									detailData.branches = branches;
								} else {
									header.details.push({
										batch_number: header.batch_number,
										payee_id: excelValue.payee_id,
										department_id: excelValue.department_id,
										section_id: (!excelValue.section_id ? 0 : excelValue.section_id),
										regionalhead_id: (!excelValue.section_id ? 0 : excelValue.section_id),
										branch_id: excelValue.branch_id,
										currency_id: excelValue.currency_id,
										account_code: excelValue.account_code,
										document_number: detailData.document_number,
										document_date: format(new Date(), 'yyyy-MM-dd'),
										remarks: excelValue.remarks,
										debit_amount: excelValue.debit_amount,
										credit_amount: excelValue.credit_amount,
										sections: sections,
										branches: branches
									});
								}
							});
						}
					});
				}
			});
		},
		getDeptData(dept_id, index, document_number) {
			var filterDetails = [];
			var details = [];
			var concatDetails = [];

			filterDetails = this.documentDetails.filter(header => header.document_number === document_number);
			details = filterDetails[0].details;
			
			this.dataDepts.filter(depts_details => {
				if(depts_details.id == dept_id){
					if (depts_details.sections.length > 0) {
						details[index].sections = depts_details.sections;
					} else {
						details[index].sections = [];
					}

					if(dept_id == 1 || dept_id == 25 || dept_id == 26 || dept_id == 29){
						details[index].branches = this.branches.filter(e => e.brnc_stat === 'OPEN');
					} else {
						details[index].branches = this.branches.filter(det => det.brnc_code === 'HOB' && det.brnc_stat === 'OPEN');
					}

					if(dept_id !== 6 && dept_id !== 25 && dept_id !== 26 && dept_id !== 29 && dept_id !== 30){
						details[index].branch_id = 114;
					} else {
						details[index].branch_id = '';
					}
				}
			});

			concatDetails = filterDetails.concat(details.filter((item) => filterDetails.indexOf(item) < 0));
			this.details = concatDetails;
		},
		getSectionData(dept_id, index, document_number) {
			var filterDetails = [];
			var details = [];
			var concatDetails = [];

			filterDetails = this.documentDetails.filter(header => header.document_number === document_number);
			details = filterDetails[0].details;
			
			this.dataDepts.filter(depts_details => {
				if(depts_details.id == dept_id){
					if (depts_details.sections.length > 0) {
						details[index].sections = depts_details.sections;
					} else {
						details[index].sections = [];
					}
				}
			});

			concatDetails = filterDetails.concat(details.filter((item) => filterDetails.indexOf(item) < 0));
			this.details = concatDetails;
		},
		getBranchData(dept_id, index, document_number) {
			var filterDetails = [];
			var details = [];
			var concatDetails = [];

			filterDetails = this.documentDetails.filter(header => header.document_number === document_number);
			details = filterDetails[0].details;

			if(dept_id == 1 || dept_id == 25 || dept_id == 26 || dept_id == 29){
				details[index].branches = this.branches.filter(e => e.brnc_stat === 'OPEN');
			} else {
				details[index].branches = this.branches.filter(det => det.brnc_code === 'HOB' && det.brnc_stat === 'OPEN');
			}

			if(dept_id !== 6 && dept_id !== 25 && dept_id !== 26 && dept_id !== 29 && dept_id !== 30){
				details[index].branch_id = 114;
			} else {
				details[index].branch_id = '';
			}

			concatDetails = filterDetails.concat(details.filter((item) => filterDetails.indexOf(item) < 0));
			this.details = concatDetails;
		},
		getTotalPage(){
			//const docNumber = parseInt(doc_id);
			const filterDetails = this.doc_detail.details;
			let count = filterDetails.length / this.rowPage;
			let dec = count.toString().split('.');
			let total = 1;
			if(dec[1]){
				total = parseInt(dec[0]) + 1;
			}else{
				total = count;
			}

			return parseInt(total);
		},
		setDetails(id, key, header_document){
			this.getBranches();
			// this.getSections();
			// this.getDepts();

			const filterDetails = this.doc_detail.details;
			let details = [];
			details = filterDetails[0].details;

			details[key].branch_id = '';
			details[key].section_id = '';
			details[key].department_id = '';

			this.payees.filter(payee_details => {
				

				if(payee_details.id == id) {
					this.branches.filter(branches_details => {
						if(branches_details.brnc_code == payee_details.brnc_code){
							details[key].branch_id = branches_details.id;
						}
					});
					
					this.dataSections.filter(section_details => {
						if(section_details.section_code == payee_details.section_code){
							details[key].section_id = section_details.id;
						}
					});

					this.dataDepts.filter(depts_details => {
						if(depts_details.dept_code == payee_details.dept_code){
							details[key].department_id = depts_details.id;
						}
					});

					this.getDeptData(details[key].department_id, key, header_document);
				}
			});
		},
		changeNextData(){
			let pages = parseInt(this.page);
			this.page = pages + 1;
		},
		changePrevData() {
			let pages = parseInt(this.page);
			this.page = pages - 1;
		}
	}
};
</script>
<style scoped>
  .v-autocomplete {
    font-size: 14px
  }
  .amount {
    font-size: 14px
  }
</style>
